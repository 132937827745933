import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import * as homeStyles from "../../css/index.module.css"
import { Button } from "react-bootstrap"
import { Icon } from '@iconify/react';
import arrowRight from '@iconify/icons-akar-icons/arrow-right';

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "interior-large-distribution-warehouse.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 350) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <a href="/other-products/">
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#fff`}
        >
            <div className={homeStyles.buttomcategory}>
                <h3 className={homeStyles.hcat}>สินค้าอื่นๆ</h3>
                <Button className={homeStyles.buttomcat} rel="noreferrer" href="/other-products/">ดูสินค้า <Icon icon={arrowRight} /></Button>{' '}
            </div>
        </BackgroundImage>
        </a>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: center center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection