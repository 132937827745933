import React from "react"
import Containers from "../components/container"
import Header from "../components/header"
import Headertop from "../components/headertop"
import Footer from "../components/footer"
import Formfooter from "../components/form-footer"
import { Container, Col, Row, Button } from "react-bootstrap"
import * as homeStyles from "../css/index.module.css"
import { Icon } from '@iconify/react';
import arrowRight from '@iconify/icons-akar-icons/arrow-right';
import mobileIcon from '@iconify/icons-bytesize/mobile';
import locationIcon from '@iconify/icons-bytesize/location';
import Facebook from "../images/facebook-qrcode.png"
import Line from "../images/line-qrcode.png"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"
import Slide from "../components/slide"
import Seo from "../components/seo"
import downloadFilePDF from "../images/STT-company-profile-sewing-thread.pdf"

//background content
import Bghome from "../components/bghome"
import Bgcatgarment from "../components/home/bgcatgarment"
import Bgcatsawasack from "../components/home/bgcatsawasack"
import Bgcatindustrial from "../components/home/bgcatindustrial"
import Bgcatimport from "../components/home/bgcatimport"
import Bgcatother from "../components/home/bgcatother"

const Seotitle = "ผู้ผลิตและนำเข้า อุปกรณ์ตัดเย็บ เล้นด้าย"
const seoDescription = "ผู้ผลิตนำเข้าและจำหน่ายอุปกรณ์ตัดเย็บเส้นด้ายอุตสาหกรรม ทุกชนิด ประสบการณ์กว่า 60 ปี รับจ้างตีเกียว รับจ้างย้อม รับจ้างผลิตใส่แบรนด์ OEM"
const facebook = "https://www.facebook.com/ผลิตและจำหน่าย-ด้ายเย็บกระสอบ-ด้ายการ์เม้นท์และด้ายอุตสาหกรรม-ทุกชนิด-101655694919457/";
const line = "https://lin.ee/7y11wvo";

export default function Home({data}) {
  return (
    <Containers>
      <Seo 
        title={Seotitle} 
        description={seoDescription}
      />
      <Headertop />
      <Header />
      <Slide />
        <div className={homeStyles.herohome}>
          <Container fluid="xl">
            <Row>
              <Col><h1 className={homeStyles.headerfont}>ผู้ผลิตนำเข้าและจำหน่ายอุปกรณ์ตัดเย็บเส้นด้ายอุตสาหกรรม ทุกชนิด</h1></Col>
            </Row>
            <Row>
              <Col><h3 className={homeStyles.headerfonta}>ประสบการณ์กว่า 60 ปี</h3></Col>
            </Row>
            <Row>
              <Col className={homeStyles.colservice}>
                <ul className={homeStyles.buttomflex}>
                  <li className={homeStyles.herobuttom}>รับจ้างตีเกลียว</li>
                  <li className={homeStyles.herobuttom}>รับจ้างย้อม</li>
                  <li className={homeStyles.herobuttom}>รับจ้างผลิตใส่แบรนด์ OEM</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className={homeStyles.buttomflex}>
                  <li className={homeStyles.herobuttom}>รับจ้างนำเข้าสินค้าจากต่างประเทศ</li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col><p className={homeStyles.headerfontb}>Original Since 1962</p></Col>
            </Row>
          </Container>
        </div>
      <div className={homeStyles.herosection}>
      <Container fluid="xl">
          <Row>
            <Col md={4}><Bgcatgarment /></Col>
            <Col md={4}><Bgcatsawasack /></Col>
            <Col md={4}><Bgcatindustrial /></Col>
          </Row>
          <Row>
            <Col></Col>
          </Row>
          <Row>
            <Col md={2}></Col>
            <Col md={4}><Bgcatimport /></Col>
            <Col md={4}><Bgcatother /></Col>
            <Col md={2}></Col>
          </Row>
          <Row>
            <Col><div className={homeStyles.buttomdownload}><center><Button className={homeStyles.buttomdown} rel="noreferrer" href={downloadFilePDF} target="_blank">ดาวน์โหลด PDF <Icon icon={arrowRight} /></Button>{' '}</center></div></Col>
          </Row>
        </Container>
      </div>
      <div className={homeStyles.herosection}>
      <Container fluid="xl">
        <Row>
          <Col><center><h2 style={{paddingBottom:"20px"}}>สินค้าขายดี</h2></center></Col>
        </Row>
          <Row>
          {data.allMarkdownRemark.edges.map(({ node }) => (
            <Col md={3} xs={6} key={node.id}>
              <Link to={node.fields.slug}>
                <Img fluid={node.frontmatter.featuredImage.childImageSharp.fluid} className={homeStyles.imgproduct} />
                  <h3>{node.frontmatter.title}{" "}</h3>
              </Link>
            </Col>))}
          </Row>
          <Row>
            <Col><div className={homeStyles.buttomdownload}><center><Button className={homeStyles.buttomdown} rel="noreferrer" href="/categories/">ดูสินค้าทั้งหมด <Icon icon={arrowRight} /></Button>{' '}</center></div></Col>
          </Row>
        </Container>
      </div>
      <Bghome />
      <div className={homeStyles.herosection}>
      <Container fluid="xl">
          <Row>
            <Col md={6}><h3 className={homeStyles.headera}>ติดต่อสำนักงาน</h3>
            <p className={homeStyles.par}>บริษัท ทรงไทยเท็กซ์ไทล์ จำกัด ผู้ผลิตและจำหน่ายอุปกรณ์ตัดเย็บ และ เส้นด้ายอุตสาหกรรม ทุกชนิด เราเป็นโรงงานผลิตและจำหน่าย ด้ายเย็บกระสอบ มีความยืดหยุ่นและทนแรงดึงสูง 
              ด้ายเย็บกระสอบสำหรับเย็บกระสอบบรรจุข้าวสาร กระสอบปุ๋ย กระสอบแป้ง กระสอบน้ำตาล เม็ดพลาสติก เหมาะสำหรับใช้กับจักรเย็บกระสอบนิวลองทุกรุ่น</p>
            <ul className={homeStyles.contactflex}>
              <li><Icon icon={locationIcon} height={32} /></li>
              <li><p className={homeStyles.plive}><b>ที่อยู่</b></p><p className={homeStyles.plivedes}>33/16-27 หมู่ 3 ถนนเพชรเกษม 110 หนองค้างพลู หนองแขม กทม. 10160</p></li>
            </ul>
            <ul className={homeStyles.contactflex}>
              <li><Icon icon={mobileIcon} height={28} /></li>
              <li><p className={homeStyles.plive}><b>เบอร์โทรศัพท์</b></p><p className={homeStyles.plivedes}>081-766-7977</p></li>
            </ul>
            </Col>
            <Col md={6}>
              <center>
                <h3 className={homeStyles.headera}>QR Code</h3>
              </center>
              <Containers>
                <Row>
                  <Col xs={6}>
                    <center>
                      <a aria-label="facebook" href={facebook} target="_blank" rel="noreferrer">
                        <img src={Facebook} alt="Facebook QR Code" />
                        <p className={homeStyles.psocialfacebook}>Facebook Page</p>
                      </a>
                    </center>
                  </Col>
                  <Col xs={6}>
                    <center>
                      <a aria-label="line" href={line} target="_blank" rel="noreferrer">
                        <img src={Line} alt="Line QR Code" />
                        <p className={homeStyles.psocialline}>Line Official</p>
                      </a>
                    </center>
                  </Col>
                </Row>
              </Containers>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className={homeStyles.herosectionmap}>
      <Container fluid="xl">
          <Row>
            <Col>
                  <iframe
                    title="Map Songthai"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2304.6820363859333!2d100.34996369155941!3d13.718587345092471!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e297e49175b095%3A0x1b0f229540149a25!2z4Lia4Lij4Li04Lip4Lix4LiXIOC4l-C4o-C4h-C5hOC4l-C4ouC5gOC4l-C5h-C4geC4i-C5jOC5hOC4l-C4peC5jCDguIjguLPguIHguLHguJQgU29uZ1RoYWkgVGV4dGlsZSBDby4sIEx0ZC4!5e0!3m2!1sen!2sth!4v1630076965008!5m2!1sen!2sth"
                    width="100%"
                    height="400"
                    frameBorder="0"
                    style={{ border: 0, borderRadius:"10px" }}
                    allowFullScreen=""
                    aria-hidden="false"
                  />
            </Col>
          </Row>
        </Container>
      </div> */}
        <Container className="pb-5">
          <Row>
            <Col md={12}>
              <p>การนำเข้าและผลิตสินค้าด้ายอุตสาหกรรมเป็นกระบวนการที่เกี่ยวข้องกับการผลิตและการค้าขายด้ายที่มีการใช้ด้ายในอุตสาหกรรมต่าง ๆ ต่อไปนี้คือขั้นตอนที่มักเกี่ยวข้อง</p>
              <h5>การนำเข้าด้ายอุตสาหกรรม:</h5>
              <ul>
                <li>วิเคราะห์ตลาด: การวิเคราะห์ตลาดเพื่อทำความเข้าใจความต้องการของตลาดและทิศทางการเปลี่ยนแปลง</li>
                <li>ค้นหาผู้ผลิต: การค้นหาและเลือกผู้ผลิตด้ายที่มีคุณภาพและมีประสบการณ์ในการสร้างด้ายอุตสาหกรรม</li>
                <li>การนำเข้าด้าย: การทำธุรกรรมนำเข้าด้ายจากผู้ผลิตต่าง ๆ ที่เลือก</li>
                <li>ทำสัญญาและการต่อรอง: การทำสัญญาการนำเข้าและต่อรองเงื่อนไขการซื้อขาย</li>
                <li>จัดการการขนส่ง: การจัดการกระบวนการขนส่งด้ายจากที่ผลิตมายังสถานที่ผลิตสินค้า</li>
                <li>การนำเข้าศุนย์กลาง: การรวบรวมด้ายที่นำเข้ามาที่ศุนย์กลางหรือโกดัง</li>
              </ul>
              <h5>การผลิตสินค้าด้ายอุตสาหกรรม:</h5>
              <ul>
                <li>การวางแผนการผลิต: การวางแผนการผลิตด้ายตามความต้องการของตลาด</li>
                <li>จัดหาวัตถุดิบ: การจัดหาวัตถุดิบที่ใช้ในการผลิตด้าย</li>
                <li>กระบวนการผลิต: การใช้เครื่องจักรและกระบวนการที่เหมาะสมในการสร้างด้ายอุตสาหกรรม</li>
                <li>ตรวจสอบคุณภาพ: การตรวจสอบคุณภาพของด้ายที่ผลิตเพื่อให้มั่นใจว่ามีมาตรฐานที่ต้องการ</li>
                <li>การบรรจุและจัดเก็บ: การบรรจุสินค้าและจัดเก็บพร้อมส่งมอบ</li>
                <li>การตลาดและการขาย: การตลาดและขายด้ายอุตสาหกรรมให้กับลูกค้าในตลาด</li>
              </ul>
              <h5>การวิจัยและพัฒนา:</h5>
              <ul>
                <li>การวิจัยและพัฒนาด้าย: การดำเนินการวิจัยและพัฒนาเพื่อปรับปรุงคุณภาพและลดต้นทุนการผลิต</li>
                <li>นวัตกรรมในด้าย: การสร้างนวัตกรรมในด้ายอุตสาหกรรมเพื่อตอบสนองต่อความต้องการของตลาด</li>
              </ul>
              <p>การนำเข้าและผลิตสินค้าด้ายอุตสาหกรรมเป็นกระบวนการที่ซับซ้อนและต้องการการบริหารจัดการที่ดีเพื่อให้ได้ผลลัพธ์ที่เหมาะสมและมีคุณภาพ</p>
              <h5>การตลาดและการขาย:</h5>
              <ul>
                <li>การวางราคา: การกำหนดราคาสินค้าด้ายที่เป็นไปตามความคุ้มค่าและความสามารถในตลาด</li>
                <li>การส่งเสริมการขาย: การใช้กลยุทธ์การโปรโมทและการส่งเสริมการขายเพื่อเพิ่มการรับรู้และยอมรับจากลูกค้า</li>
                <li>การค้าปลีกและการค้าส่ง: การจัดหาช่องทางการจำหน่ายที่เหมาะสม เช่น การทำธุรกรรมทางออนไลน์, การค้าปลีก, และการค้าส่ง</li>
              </ul>
              <h5>การบริหารทรัพยากรมนุษย์:</h5>
              <ul>
                <li>การฝึกอบรม: การฝึกอบรมพนักงานทั้งในด้ายอุตสาหกรรมและส่วนที่เกี่ยวข้อง เพื่อเพิ่มประสิทธิภาพและความเชี่ยวชาญ</li>
                <li>การบริหารแบบองค์รวม: การจัดการทรัพยากรมนุษย์ทั้งหมดในองค์กรเพื่อให้ได้ผลลัพธ์ที่ดี</li>
              </ul>

              <h5>การรับมือกับความเปลี่ยนแปลง</h5>
              <ul>
                <li>การปรับตัวในตลาด: การปรับตัวต่อการเปลี่ยนแปลงในตลาด เช่น การพัฒนาผลิตภัณฑ์ใหม่หรือปรับปรุงด้าย</li>
                <li>การดำเนินการเพื่อความยั่งยืน: การดำเนินกิจกรรมที่สามารถรองรับความยั่งยืนของธุรกิจในระยะยาว</li>
              </ul>

              <h5>การปฏิสัมพันธ์กับลูกค้าและพันธมิตรธุรกิจ:</h5>
              <ul>
                <li>บริการลูกค้า: การให้บริการลูกค้าที่ดีเพื่อสร้างความพึงพอใจ</li>
                <li>พันธมิตรธุรกิจ: การพัฒนาความร่วมมือกับพันธมิตรที่สามารถสนับสนุนและเสริมสร้างธุรกิจ</li>
              </ul>
              <h5>การติดตามและประเมิน</h5>
              <ul>
                <li>การติดตามผล: การวัดและติดตามผลการดำเนินการเพื่อปรับปรุงแผนภาพทางธุรกิจ</li>
                <li>การประเมินความสำเร็จ: การวิเคราะห์และประเมินผลการทำงานเพื่อพัฒนาและปรับปรุงการดำเนินธุรกิจ</li>
              </ul>
              <p>การนำเข้าและผลิตสินค้าด้ายอุตสาหกรรมเป็นกระบวนการที่ให้ความสำคัญกับการบริหารจัดการทุกขั้นตอนของธุรกิจเพื่อให้ได้ผลลัพธ์ที่ดีและมีความยั่งยืนในตลาด</p>
            </Col>
          </Row>
        </Container>
      <Formfooter />
      <Footer />
    </Containers>
  );
}

export const query = graphql`
  query {
    allMarkdownRemark(
      limit: 4
      filter: { frontmatter: { key: { eq: "product" }}}
      sort: { fields: [frontmatter___date], order: DESC }
      ) {
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`