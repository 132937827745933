import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'
import * as homeStyles from "../css/index.module.css"
import { Container, Col, Row, Button } from "react-bootstrap"
import { Icon } from '@iconify/react';
import arrowRight from '@iconify/icons-akar-icons/arrow-right';

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero-a.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#040e18`}
        >
          <Container fluid="xl" className={homeStyles.heroa}>
          <Row>
            <Col md={4}></Col>
            <Col md={4}><div className={homeStyles.headerdiv}><h2 className={homeStyles.headerb}>แรงบันดาลใจ ในการให้บริการ</h2></div></Col>
            <Col md={4}></Col>
          </Row>
          <Row>
            <Col md={3}></Col>
            <Col md={3} xs={6}><Button className={homeStyles.herobuttoma} rel="noreferrer" href="/blog/">บทความ <Icon icon={arrowRight} /></Button>{' '}</Col>
            <Col md={3} xs={6}><Button className={homeStyles.herobuttomb} rel="noreferrer" href="/about-us/">เกี่ยวกับเรา <Icon icon={arrowRight} /></Button>{' '}</Col>
            <Col md={3}></Col>
          </Row>
        </Container>
        </BackgroundImage>
      )
    }}
  />
)

const StyledBackgroundSection = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
`

export default StyledBackgroundSection