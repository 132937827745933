import React, { Component } from "react";
import Slider from "react-slick";
import { StaticImage } from "gatsby-plugin-image"
import * as homeStyles from "../css/index.module.css"

export default class MultipleItems extends Component {
  render() {
    const settings = {
      dots: true,
      fade: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      dotsClass: "slick-dots",
    };
    return (
        <Slider {...settings} className={homeStyles.heroside}>
          <div>
            <StaticImage className={homeStyles.heroside} src="../images/hero-edit.jpg" alt="Slide01" />
          </div>
          <div>
            <StaticImage className={homeStyles.heroside} src="../images/hero-two.jpg" alt="Slide02" />
          </div>
          <div>
            <StaticImage className={homeStyles.heroside} src="../images/hero-three.jpg" alt="Slide03" />
          </div>
        </Slider>
    );
  }
}